export const getApiUrl = () => {
  if (window.location.hostname.includes('dev')) {
    return 'https://dev-api.app.armedconflict.law/';
  }

  if (window.location.hostname.includes('localhost')) {
    return 'https://dev-api.app.armedconflict.law/';
  }

  return 'https://api.app.armedconflict.law/';
};
