import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { getApiUrl } from '../utils/common';
import { authSlice } from './auth/auth.slice';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: getApiUrl(),
  prepareHeaders: (headers, { getState }) => {
    const { jwtAuthenticationToken, language } = getState().authSlice;

    if (jwtAuthenticationToken) {
      headers.set('Authorization', `Bearer ${jwtAuthenticationToken}`);
    }

    headers.set('x-lang', language);

    return headers;
  },
  subscribe: false,
});

const baseQueryWithRefresh = async (args, api, extraOptions) => {
  const { jwtAuthenticationToken, refreshAuthenticationToken } =
    api.getState().authSlice;

  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  let requestResult = await baseQuery(args, api, extraOptions);
  if (
    requestResult.error &&
    requestResult.error.status === 401 &&
    jwtAuthenticationToken !== null
  ) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQuery(
          {
            body: {
              refreshToken: refreshAuthenticationToken,
            },
            method: 'POST',
            url: 'refresh-token',
          },
          api,
          extraOptions
        );

        if (refreshResult.data?.data) {
          api.dispatch(authSlice.actions.setTokens(refreshResult.data.data));
          // retry the initial query
          requestResult = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(authSlice.actions.signOut());
          api.dispatch(api.util.resetApiState());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      requestResult = await baseQuery(args, api, extraOptions);
    }
  }
  return requestResult;
};

export const api = createApi({
  baseQuery: baseQueryWithRefresh,
  endpoints: () => ({}),
  keepUnusedDataFor: 0,
  reducerPath: 'api',
  tagTypes: ['user', 'scenarios'],
});
